<script>
    import Header from "./lib/Header.svelte";
</script>

<Header></Header>
<main class="p-8">
    <h1 class="text-4xl my-4">404 – Seite nicht gefunden</h1>
    <p>
        Die angeforderte Seite konnte nicht gefunden werden. <a
            class="text-green-700"
            href="/">Zurück zur Startseite</a
        >
    </p>
</main>
